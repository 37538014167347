import {
  Button,
  ButtonGroup,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader
} from '@marketgoo/components';
import {
  ArrowElbowLeft,
  Graph,
  HourglassMedium,
  User
} from '@phosphor-icons/react';
import {profileSelected, resetSelectProfile} from 'app/Data/plans/plan/actions';
import AnalyticsLogoLink from 'components/Analytics/AnalyticsLogoLink';
import List from 'components/UI/List';
import {modalHide} from 'components/UI/Modal/actions';
import PT from 'prop-types';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const Profile = ({item}) => (
  <div className="list-profile-container">
    <div className="icon"></div>
    <div className="profile">
      <div>
        <div>
          <span className="title-container">{item?.website_url}</span>
        </div>
        <div>
          <span>{item?.name}</span>
        </div>
      </div>
      <div>{'[' + item?.web_property_id + ']'}</div>
    </div>
  </div>
);

const ProfileNotFound = () => (
  <div className="profile-not-found">
    <AnalyticsLogoLink />
    <h5>{__('The profile your are looking for is not on the list?')}</h5>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="//analytics.google.com/analytics"
      onClick={() => (window.location.href = '/')}
    >
      {__('Go to your Google Analytics account!')}
    </a>
  </div>
);

const EmptyData = () => (
  <div className="profile-not-found">
    <h4>{__('There are not any profile to connect.')}</h4>
  </div>
);

const GoogleAnalyticsProfiles = ({profiles = []}) => {
  const dispatch = useDispatch();
  const uuid = useSelector((state) => state.data.config?.current_plan);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [sending, setSending] = useState(false);

  const handleConnect = () => {
    if (selectedProfile) {
      setSending(true);
      dispatch(profileSelected(selectedProfile));
    }
  };

  const closeModal = () => dispatch(modalHide());
  const cancelProfile = () => {
    dispatch(modalHide());
    dispatch(resetSelectProfile(uuid));
  };

  return (
    <Modal open onClose={closeModal}>
      <ModalHeader title={__('Connect Google Analytics')} />
      <ModalContent variant="scroll" className="modal-analytics-profiles">
        <AnalyticsLogoLink />
        {profiles.length > 0 ? (
          <div>
            <p>
              {__(
                'You may have more than one property associated to your Analytics account. Choose the profile related to your site.'
              )}
            </p>
            <List
              data={profiles}
              components={[Profile]}
              onSelect={setSelectedProfile}
              selected={selectedProfile?.id}
              selectedProp="id"
            />
          </div>
        ) : (
          <EmptyData />
        )}
        <ProfileNotFound />
      </ModalContent>
      <ModalFooter>
        <ButtonGroup variant="reversed">
          <Button onClick={cancelProfile}>{__('Cancel')}</Button>
          <Button
            variant="primary"
            busy={sending && __('Please wait...')}
            disabled={!selectedProfile}
            onClick={handleConnect}
          >
            {__('Connect')}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

GoogleAnalyticsProfiles.propTypes = {
  profiles: PT.array
};

export default GoogleAnalyticsProfiles;
