import {getMonth, sub} from 'date-fns';
import {currentLocale} from 'lib/datetime';
import cookies from 'misc/cookies';
import {normalize, schema} from 'normalizr';
import __ from '../lib/i18n';

export const getOFSQuery = (options = {}) => {
  const {filters = [], limit = 10, offset = 0, sort = ''} = options;
  const params = new URLSearchParams();

  filters.forEach((filter) => {
    if (filter.includes('search')) {
      const searchValue = filter.split('=')[1];
      if (searchValue) {
        params.append('filter[search]', searchValue.trim());
      }
    } else if (filter.includes('score')) {
      const progressValue = filter.split('=')[1];
      if (progressValue) {
        params.append('filter[score]', progressValue.trim());
      }
    }
  });

  params.append('page[offset]', offset);
  params.append('page[limit]', limit);
  if (sort) {
    params.append('sort', sort);
  }

  return `?${params.toString()}`;
};

const daysBetweenDates = (start, end, history, step = 1, zero_is_null) => {
  let iDate = new Date(end);
  var days = [];
  let cont = 0;
  while (iDate <= start) {
    days.push({
      date: iDate.toISOString().split('T')[0],
      value: zero_is_null
        ? history[cont] > 0
          ? history[cont]
          : null
        : history[cont] >= 0
          ? history[cont]
          : null
    });
    cont = cont + 1;
    iDate.setDate(iDate.getDate() + step);
  }
  return days;
};

export const getMonthlyFromYearHistory = (history) => {
  if (!history) return [];
  const currentMonth = getMonth(new Date());
  const currentYear = new Date().getFullYear();
  const result = [];
  for (let i = 0; i < history.length; i++) {
    let targetMonth = currentMonth - i;
    let targetYear = currentYear;
    if (targetMonth < 0) {
      targetMonth += 12; // Ajustar el mes al año anterior si es negativo
      targetYear -= 1; // Decrementar el año
    }
    const lastDateUsed = new Date(targetYear, targetMonth);
    result.push({
      date: lastDateUsed,
      value: history[i]
    });
  }
  return result;
};

// export const getMonthlyFromYearHistory = (history) => {
//   if (!history) return [];
//   const currentMonth = getMonth(new Date());
//   const result = [];
//   let lastDateUsed = new Date();
//   for (let i = history.length - 1; i >= 0; i--) {
//     lastDateUsed.setMonth(currentMonth - i);
//     result.push({
//       date: new Date(lastDateUsed),
//       value: history[i]
//     });
//   }
//   console.log('result', result);
//   return result;
// };

export const getBiWeeklyFromWeeklyHistory = (history) => {
  // Desde Backend tenemos un array con los ultimos 3 meses divididos por semanas de 8 días.
  // Que corresponden a los días 1,9,17 y 25
  // Esto se hace para que siempre tengamos meses de 4 semanas y no de 5.
  // Por este motivo usamos estos días para matchear días con valores.
  if (!history) return [];
  const DAY_WEEK_ONE = 1;
  const DAY_WEEK_TWO = 9;
  const DAY_WEEK_THREE = 17;
  const DAY_WEEK_FOUR = 25;

  const currentDay = new Date().getDate();
  const isEven = (i) => i % 2 === 0;
  const isOdd = (i) => i % 2 !== 0;
  const isSecondWeek =
    currentDay >= DAY_WEEK_TWO && currentDay < DAY_WEEK_THREE;
  const isFourthWeek = currentDay >= DAY_WEEK_FOUR;
  const fnToFilterHistory = isSecondWeek || isFourthWeek ? isEven : isOdd;
  const historyFiltered = history.filter((_, index) =>
    fnToFilterHistory(index)
  );

  const result = [];
  let lastDayUsed =
    currentDay >= DAY_WEEK_THREE ? DAY_WEEK_THREE : DAY_WEEK_ONE;
  let lastDateUsed = new Date();
  for (let i = historyFiltered.length - 1; i >= 0; i--) {
    lastDateUsed.setDate(lastDayUsed);
    result.push({
      date: new Date(lastDateUsed),
      value: historyFiltered[i]
    });
    if (lastDayUsed === DAY_WEEK_ONE)
      lastDateUsed.setMonth(lastDateUsed.getMonth() - 1);
    lastDayUsed =
      lastDayUsed === DAY_WEEK_THREE ? DAY_WEEK_ONE : DAY_WEEK_THREE;
  }
  return result.reverse();
};

export const historyToChartData = (history, zero_is_null) => {
  let resultObj = {last3m: [], month: [], last15: []};

  if (history) {
    let now = new Date();
    let startDate = new Date();
    let endDate = new Date(startDate.setDate(1)).setMonth(
      startDate.getMonth() - 2
    );
    let last3m = daysBetweenDates(
      now,
      endDate,
      history?.weekly,
      7,
      zero_is_null
    );

    startDate = new Date();
    endDate = startDate.setDate(startDate.getDate() - 14);
    now = new Date();
    let last15 = daysBetweenDates(
      now,
      endDate,
      history?.daily,
      1,
      zero_is_null
    );
    Object.assign(resultObj, {last3m, last15});
  }
  return resultObj;
};

export const normalizeData = (json, key) => {
  if (json) {
    const elem = new schema.Entity('elem', {}, {idAttribute: key});
    return normalize(json, [elem]).entities.elem;
  }
  return {};
};

export const PopupCenter = (url, title, w, h) => {
  // Obtener posición de la pantalla (soporta múltiples monitores)
  const dualScreenLeft = window.screenLeft ?? window.screenX;
  const dualScreenTop = window.screenTop ?? window.screenY;

  // Obtener dimensiones de la ventana actual
  const width =
    window.innerWidth || document.documentElement.clientWidth || screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    screen.height;

  // Calcular posición centrada
  const left = (width - w) / 2 + dualScreenLeft;
  const top = (height - h) / 2 + dualScreenTop;

  // Abrir ventana emergente
  return window.open(
    url,
    title,
    `scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`
  );
};

const AFFILIATE_TRACKING_QUERY = 'partid';
const AFFILIATE_TRACKING_COOKIE = 'mktgpartid';

export const getAffiliateTracking = () => {
  let query_value =
    decodeURIComponent(
      (new RegExp(
        '[?|&]' + AFFILIATE_TRACKING_QUERY + '=' + '([^&;]+?)(&|#|;|$)'
      ).exec(location.search) || [null, ''])[1].replace(/\+/g, '%20')
    ) || null;
  let cookie_value = cookies.getItem(AFFILIATE_TRACKING_COOKIE);

  return query_value || cookie_value;
};

export const getURLParameter = (name) => {
  return (
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.search
      ) || [null, ''])[1].replace(/\+/g, '%20')
    ) || null
  );
};

export const scoreToGrade = (score) => {
  if (score >= 80) return 'a_grade';
  if (score >= 70) return 'b_grade';
  if (score >= 40) return 'c_grade';
  return 'd_grade';
};

export const truncLongText = (text, n, useWordBoundary = true) => {
  if (text.length <= n) {
    return text;
  }
  const subString = text.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '...'
  );
};

export const getTitleFromPage = (page) => {
  if (page) {
    const urlIsHome = page?.url === '/';
    return urlIsHome
      ? __('Home Page')
      : page?.title
        ? page?.title
        : page?.url || null;
  }
  return null;
};

export const getVariantByScore = (score) => {
  const normalizedScore = typeof score === 'string' ? parseInt(score) : score;
  if (normalizedScore >= 85) return 'success';
  if (normalizedScore > 50) return 'warning';
  return 'error';
};

export const getDiffLevel = (current, diff) => {
  const variation = diff ? Math.round((diff / (current - diff)) * 100) : 0;
  // ha subido más del 10%
  if (variation > 10) return 'muchbetter';
  // ha subido más del 2%
  if (variation > 2) return 'better';
  // ha bajado más del -2%
  if (variation < -2) return 'worse';
  // no ha cambiado mucho, la variación está entre +2% and -%2
  return 'equals';
};

export const getLevelFromScore = (progress) => {
  const normalizedProgress =
    typeof progress === 'string' ? parseInt(progress) : progress;
  if (normalizedProgress >= 100) return 'highest';
  if (normalizedProgress >= 85) return 'high';
  if (normalizedProgress > 50) return 'medium';
  return 'low';
};

export const capitalize = (word) => {
  if (typeof word !== 'string') return '';
  return word && word.charAt(0).toUpperCase() + word.slice(1);
};

export const getMaxValueFromCollection = (collection) =>
  collection.reduce((acc, element) => (acc = acc > element ? acc : element), 0);

export const getLastMonths = ({
  numberOfMonths = 12,
  includeCurrentMonth = true,
  monthLength = 'long'
} = {}) => {
  const baseDate = new Date();
  const offset = includeCurrentMonth ? 0 : 1;

  return Array.from({length: numberOfMonths || 1}, (_, index) =>
    capitalize(
      sub(baseDate, {months: index + offset}).toLocaleDateString(
        currentLocale,
        {month: monthLength}
      )
    )
  ).reverse();
};

// Recibe un history y devuleve un boolean.
// Mostramos el char si tenemos valores para los 2 ultimos meses
export const isCurrentMonthComparableWithPrevious = (history) => {
  return (
    history !== null &&
    history.length > 1 &&
    history[history.length - 1] !== null &&
    history[history.length - 2] !== null
  );
};

/**
 * normalizeRange
 *
 * Returns a number with a normalized range based on the max and min value.
 * Useful for calculating ChartColumnValue values where is a number between 0 and 1.
 * @param {val} Value of current element
 * @param {max} Max value of all elements
 * @param {min} Min value of all elements
 * @returns {number}
 */
export function normalizeRange(val, max, min = 0) {
  if (val === null || val === undefined) {
    return null;
  }
  if (max === min) {
    return 0;
  }
  if (val === min) {
    return val / max;
  }
  return (val - min) / (max - min);
}

/**
 * getRanges
 *
 * Execute normalizeRange to an array or multiple arrays for multiple values
 *
 * @param  {...array} rows
 * @returns {array}
 */
export function getRanges(...rows) {
  let max = 0;
  rows.forEach((row) => (max = Math.max(max, ...row)));

  return new Array(rows[0].length)
    .fill([])
    .map((value, index) => rows.map((row) => normalizeRange(row[index], max)));
}

export const competitorsColors = [
  'var(--color-primary-500)',
  'var(--color-chart-1)',
  'var(--color-chart-2)',
  'var(--color-chart-3)',
  'var(--color-chart-4)'
];

export const ordinalsNumbers = (number) => {
  let ordinal = 'th';
  const valid_ordinals = {
    1: 'st',
    2: 'nd',
    3: 'rd'
  };
  const last_digit = number.toString().slice(-1);
  const keys = Object.keys(valid_ordinals);
  const key_exists = keys.find((el) => el === last_digit);
  if (key_exists) {
    ordinal = valid_ordinals[last_digit];
  }

  if (number.toString().length > 1) {
    const last_2_digits = number.toString().slice(-2);
    const other_ordinals = ['11', '12', '13'];
    ordinal =
      other_ordinals.find((el) => el === last_2_digits) || !key_exists
        ? 'th'
        : valid_ordinals[last_digit];
  }
  return ordinal;
};

export const formatOrdinals = (number, locale) => {
  const positions = ['one', 'two', 'few', 'other'];
  const en = ['st', 'nd', 'rd', 'th'];
  const es = ['º', 'º', 'º', 'º'];
  const fr = ['er', 'e', 'e', 'e'];
  const da = ['.', '.', '.', '.'];
  const sv = [':a', ':a', ':e', ':e'];

  const buildLanguage = (language) =>
    new Map(positions.map((element, index) => [element, language[index]]));

  const suffixes =
    {
      'en-US': buildLanguage(en),
      'es-ES': buildLanguage(es),
      'fr-FR': buildLanguage(fr),
      'da-DK': buildLanguage(da),
      'pt-BR': buildLanguage(es),
      'de-DE': buildLanguage(en),
      'sv-SE': buildLanguage(sv),
      'nl-NL': buildLanguage(en),
      'en-GB': buildLanguage(en),
      'lt-LT': buildLanguage(en)
    }[locale] || buildLanguage(en);

  const pr = new Intl.PluralRules(locale, {type: 'ordinal'});
  const rule = pr.select(number);
  const suffix = suffixes.get(rule);
  console.log('suffix', suffix);
  return `${suffix}`;
};

export const copyToClipboard = (valueInput) =>
  navigator?.clipboard.writeText(valueInput);

const shortTagNames = new Map([
  ['wordpress-free', 'Wordpress Free'],
  ['wordpres', 'Wordpress'],
  ['start', 'Lite'],
  ['evolution', 'Pro']
]);

export const generateShortTagName = (productKey) =>
  shortTagNames.get(productKey) || capitalize(productKey);

const formatUnit = (value, unit) =>
  new Intl.NumberFormat(currentLocale, {
    style: 'unit',
    unit,
    unitDisplay: 'short'
  }).format(value);

export const formatDuration = (seconds) => {
  if (seconds < 60) return formatUnit(seconds, 'second');

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return remainingSeconds === 0
    ? formatUnit(minutes, 'minute')
    : `${formatUnit(minutes, 'minute')} ${formatUnit(remainingSeconds, 'second')}`;
};

export const formatNumber = (num) => {
  return new Intl.NumberFormat(currentLocale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(num);
};

export const getAbsoluteUrl = (url, domain = '') => {
  if (!url) return null;

  try {
    return new URL(url, `https://${domain}`).href;
  } catch {
    return null;
  }
};
