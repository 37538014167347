import {
  endKeywordAssign,
  resetTaskAiFeature,
  loadTasks,
  requestTaskDataById,
  responseDataPage,
  responseTaskDataById,
  startKeywordAssign,
  updateTasks,
  requestTaskAutocompleted,
  responseTaskAutocompleted
} from 'components/TasksPanel/actions';
import {modalHide, modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import config from 'lib/application-config';
import rq from 'lib/myagent';
import {takeEvery, takeLatest} from 'redux-saga';
import {call, put, select} from 'redux-saga/effects';
import {pageDataByIdRequest} from 'screens/Optimize/actions';
import SuperAgent from 'superagent';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

// CARGA DE TAREAS PARA EL PANEL
// SE EJECUTA AL INICIAR LA APP O CON AL PEDIRLO CON loadTasks
const AJAX_URL = `${config.app.route_prefix}/ajax`;

function* loadTasksSaga() {
  const endpoint = `${config.app.route_prefix}/ajax/tasks`;

  try {
    const res = yield call(SuperAgent.post, endpoint);
    if (res.body && res.body.type === 'UPDATE_TASKBOX_DATA') {
      yield put(
        updateTasks(
          config.current_plan,
          res.body.payload.tasks,
          res.body.payload.suggestions
        )
      );
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    if (process.env.NODE_ENV == 'development') console.log(e);

    // yield put(error("error_network", true));
  }
}
export function* requestTaskDataByIdSaga({payload: {taskId}}) {
  const BASE_URL = `${AJAX_URL}/task/`;
  try {
    const taskIdResponse = yield call(rq, 'get', `${BASE_URL}${taskId}`);
    const resStatus = taskIdResponse.body && taskIdResponse.statusCode === 200;
    if (!resStatus)
      yield call({context: window.location, fn: window.history.back()});
    const statusOk = resStatus && taskIdResponse.ok;
    const resWithErrors =
      statusOk && taskIdResponse.body && taskIdResponse.body.status === 'ko';

    // Si es una tarea de página, vamos a hacer una llamada a través de su parent_id para traernos los datos.//
    const parentId = taskIdResponse && taskIdResponse.body.task.parent_id;
    if (parentId) {
      const siblingsTask = yield call(rq, 'get', `${BASE_URL}${parentId}`);
      const setTaskData = siblingsTask.body.task.subtasks;
      const pageData = siblingsTask.body.task.page;
      if (pageData) {
        yield put(pageDataByIdRequest(pageData.id));
      }
      yield put(responseTaskDataById(setTaskData));
      yield put(responseDataPage(pageData));
    }
    // Si KO entendemos que es un 404 y no tenemos datos que mostrar de esta página
    if (resWithErrors) yield put(responseTaskDataById(null));
  } catch (e) {
    // En caso de error vamos siempre a redirigir a la ruta anterior
    yield call({context: window.location, fn: window.history.back()});
  }
}

function* keywordAssignSaga(action) {
  try {
    yield call(SuperAgent.put, '/ajax/optimize/' + action.payload.task_id, {
      name: action.payload.keyword?.name,
      tracked: action.payload.add_to_traked_list
    });
    const data = yield select((state) => state.data);
    const url = yield select(
      (state) => state?.optimizeYourPages?.page_data?.page?.url
    );
    const uuid = data.config?.current_plan;
    yield put(endKeywordAssign(action.payload.keyword, uuid));
    yield put(resetTaskAiFeature(url));
    yield put(modalHide());
  } catch (e) {
    yield put(modalError);
  }
}

function* requestTaskAutocompletedSaga({
  payload: {url, taskId, keyword, ...props}
}) {
  const endpoint = `${config.app.route_prefix}/ajax/autocomplete`;
  try {
    const res = yield call(SuperAgent.post, endpoint, {url, keyword});
    if (res?.body?.data) {
      yield put(
        responseTaskAutocompleted({
          task_id: taskId,
          title_autocomplete: res.body.data?.response?.title,
          description_autocomplete: res.body.data?.response?.description,
          is_cached: res.body.data?.is_cached,
          tries_left: res.body.data?.tries_left,
          ...props
        })
      );
    }
    if (res?.body?.errors) {
      yield put(
        responseTaskAutocompleted({
          ...props,
          error: res?.body?.errors
        })
      );
    }
  } catch (e) {
    yield put(modalError);
  }
}

function* tasks() {
  yield takeEvery(['APP_READY', loadTasks().type], loadTasksSaga);
  yield takeLatest(requestTaskDataById().type, requestTaskDataByIdSaga);
  yield takeEvery(startKeywordAssign().type, keywordAssignSaga);
  yield takeEvery(
    requestTaskAutocompleted().type,
    requestTaskAutocompletedSaga
  );
}

export default tasks;
