import {Input, Tooltip, ButtonIcon} from '@marketgoo/components';
import {Copy} from '@phosphor-icons/react';
import {useState} from 'react';
import {copyToClipboard} from '../../../misc/helper';
import {useTranslation, Trans} from 'react-i18next';

export const ModalContentSchema = ({values}) => {
  const {t} = useTranslation();
  const [showClipboardMessage, setShowClipboardMessage] = useState(false);
  const handleCopyToClipboard = async (valueInput) => {
    try {
      await copyToClipboard(valueInput);
      setShowClipboardMessage(true);
      posthog?.capture('StructuredData generation schema copy');
      setTimeout(() => {
        setShowClipboardMessage(false);
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className="tools-schema-modal-content">
        <div className="tools-schema-modal-content-intro">
          <p className="tools-schema-modal-content-label ola-font-1-bold">
            {t('tools.schema.modal.input.label')}
          </p>
          <p className="ola-font-0-regular ola-color-neutral-700">
            {t('tools.schema.modal.input.hint')}
          </p>
        </div>
        <Input
          type={'textarea'}
          value={values}
          rows={16}
          icon={
            <Tooltip
              open={showClipboardMessage}
              force={'topcenter'}
              trigger={
                <ButtonIcon
                  alt={t('tools.schema.modal.input.buttonicon.alt')}
                  onClick={() => handleCopyToClipboard(values)}
                  icon={<Copy size={24} weight="fill" alt="copy" />}
                />
              }
            >
              <Trans
                i18nKey={'recocard.nodescsingle.suggestions.input.copyfeedback'}
                components={[<strong key={0} />]}
              />
            </Tooltip>
          }
        ></Input>
      </div>
      <p className="ola-font-1-regular ola-margin-top-1">
        {t('tools.schema.modal.content')}
      </p>
    </>
  );
};
