import {modalShow} from 'components/UI/Modal/actions';
import i18next from 'i18next';
import {endpoints} from 'lib/redux/sagas/api';
import {request} from 'lib/redux/sagas/request';
import {takeEvery} from 'redux-saga';
import {call, put} from 'redux-saga/effects';
import {sendStructuredData, isSubmitting, saveStructuredData} from './actions';
import {ModalContentSchema} from './components/ModalContentSchema';
import {showNotification} from 'components/UI/Notifications/actions';

export const modalError = modalShow('MODAL_INFO', {
  title: i18next.t('notification.global.error.tryagain.title'),
  description: i18next.t('notification.global.error.tryagain.description'),
  error: true
});

function* sendStructuredDataToServer(payload) {
  yield put(isSubmitting(true));
  yield put(saveStructuredData(payload));

  try {
    const res = yield call(request, endpoints.structuredData.create(), payload);
    if (res.body && res.statusCode === 200) {
      yield put(isSubmitting(false));
      yield put(
        modalShow('MODAL_INFO', {
          title: (
            <span className="ola-font-3-bold">
              {i18next.t('tools.schema.modal.title')}
            </span>
          ),
          content: (
            <ModalContentSchema
              values={`<script type="application/ld+json">\n${JSON.stringify(
                res.body.data,
                null,
                2
              )}\n</script>`}
            />
          ),
          error: true,
          confirmButtonText: i18next.t('tools.schema.modal.cta.done')
        })
      );
    } else {
      yield put(
        showNotification({
          name: 'error',
          title: i18next.t('payflow.error.step.download.intro')
        })
      );
    }
  } catch (e) {
    yield put(
      showNotification({
        name: 'error',
        title: i18next.t('payflow.error.step.download.intro')
      })
    );
  }
}

export function* toolsSaga() {
  yield takeEvery(sendStructuredData().type, sendStructuredDataToServer);
}

export default toolsSaga;
