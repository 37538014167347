import produce from 'immer';
import {
  loadMorePagesRequest,
  loadMorePagesResponse,
  nextPageSuggestedRequest,
  nextPageSuggestedResponse,
  pageDataByIdRequest,
  pageDataByIdResponse,
  pagesRequest,
  pagesResponse,
  resetPageData,
  totalPagesRequest,
  totalPagesResponse
} from 'screens/Optimize/actions';
import {resetRequestScanSingle} from 'screens/OptimizeYourPagesSuggestions/actions';
import {websocketActions} from 'app/Scanning/actions';
import {responseKeywordSuggestion} from 'screens/OptimizeYourPagesSuggestions/actions';

export const initialState = {
  busy: null,
  total_pages: null,
  total_pending_pages: null,
  pages: null,
  meta: null,
  load_more_link: null,
  load_more_busy: false,
  open_task: null,
  filters: [],
  page_data: null,
  page_busy: null,
  page_scanned: false,
  page_checking: null,
  suggested_page: null
};

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case websocketActions.SCANNING_SINGLE_TASK_FAILED:
        draft.page_checking = false;
        break;

      case websocketActions.SCANNING_SINGLE_TASK_STARTED:
        draft.page_checking = true;
        break;

      case websocketActions.SCANNING_SINGLE_TASK_COMPLETED:
        draft.page_checking = false;
        draft.page_scanned = true;
        break;

      case resetRequestScanSingle().type:
        draft.page_scanned = false;
        break;

      case nextPageSuggestedRequest().type:
        draft.suggested_page = null;
        break;

      case nextPageSuggestedResponse().type:
        draft.suggested_page = action.response;
        break;

      case pageDataByIdRequest().type:
        draft.page_data = null;
        draft.page_busy = true;
        break;

      case pageDataByIdResponse().type:
        draft.page_data = action.response;
        draft.page_busy = false;
        break;

      case resetPageData().type:
        draft.page_data = null;
        draft.page_busy = null;
        break;

      case totalPagesRequest().type:
        draft.busy = true;
        break;

      case totalPagesResponse().type:
        draft.busy = false;
        draft.total_pages = action.total_pages;
        draft.total_pending_pages = action.pending_pages;
        break;

      case pagesRequest().type:
        draft.busy = true;
        draft.suggested_page = null;
        break;

      case pagesResponse().type:
        draft.busy = false;
        draft.load_more_link = action.response.links
          ? action.response.links.next
          : null;
        draft.meta =
          action.response.data !== null ? action.response.meta : null;
        draft.pages =
          action.response.data !== null ? action.response.data : null;
        break;

      case loadMorePagesRequest().type:
        draft.load_more_busy = true;
        break;

      case loadMorePagesResponse().type:
        draft.load_more_busy = false;
        draft.load_more_link = action.response.links
          ? action.response.links.next
          : null;
        draft.pages = [...draft.pages, ...action.response.data];
        break;
      case responseKeywordSuggestion().type:
        draft.page_data = {
          ...draft.page_data,
          keyword_suggested: action.keyword
        };
        break;

      default:
        draft;
        break;
    }
  });
